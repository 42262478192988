<template>
	<div class="letter" v-if="isdiv">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		:title="pageTitle"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
		</van-nav-bar>
		<div class="invitation">
			<img class="imgbj" :src="serverUrl(imgurl)" mode="">
			<div class="invitationinputs" @click="handCarModelmodel">
				{{modeldis}}
				<img src="@/assets/drivroute/icon_xia.png" mode="">
			</div>
			<div class="invitationinputs" @click="handCarModelShow">
				{{category}}
				<img src="@/assets/drivroute/icon_xia.png" mode="">
			</div>
		</div>
		
		
		<!-- 文本组件 -->
		<div class="driveinfo_top">
			<!-- {{title1}}
			<div><span style="visibility: hidden;">空格</span> {{title2}}</div>
			<div><span style="visibility: hidden;">空格</span> {{title3}}</div> -->
			<div v-html="list3"></div>
			<div class="driveinfo_line">
				</div>
				<div  v-for="v,index in list2" :key="index">
					<input v-if="v.slice(1,v.length-1) != '车型' && v.slice(1,v.length-1) != '日期'"  placeholder-class="placeholderinput" v-model="list4[index]" class="input_name" type="text" :placeholder="v.slice(1,v.length-1)">
			
					<div v-if="v.slice(1,v.length-1) == '日期'" class="invitationinputs" @click="handtime(index)">
						{{list4[index] == ''?'点击选择日期':list4[index]}}
						<img src="@/assets/drivroute/icon_xia.png" mode="">
					</div>
					
					<div v-if="v.slice(1,v.length-1) == '车型'" class="invitationinputs" @click="handmodeltypeShow(index)">
						{{list4[index] == ''?'点击选择体验的车型':list4[index]}}
						<img src="@/assets/drivroute/icon_xia.png" mode="">
					</div>
				</div>

		</div>
		
		<!-- 我的反馈 -->
		<div v-if="feedbackinfo.length != 0" class="feedback">
			<div class="feedback_title">
				我的反馈
			</div>
			
			<div class="feedback_list" v-for="v,index in feedbackinfo" :key="index">
				<div class="feedback_list_title">
					{{v.content}}
				</div>
				<div class="feedback_list_imgs">
					<img src="@/assets/drivroute/icon_weixuanz.png" mode=""><span>是</span>
					<img style="margin-left:140px" src="@/assets/drivroute/icon_weixuanz.png" mode=""><span>否</span>
				</div>
			</div>
		</div>
		
		
		
		<!-- 公司名称 -->
		<div class="driveinfo_shop">
			<img :src="serverUrl(firminfo.images)" mode="">
			<span>{{firminfo.content[0]}}</span>
		</div>
		
		<!-- 地图组件 -->
		<div v-if="islat" class="driveinfo_map">
			<div class="driveinfo_map_left">
				<Map :center="lat"></Map>
			</div>
			<div class="driveinfo_map_rigth">
				<div class="driveinfo_map_title">
					{{mapinfo.store_name}}
				</div>
				<div class="driveinfo_map_add">
					<img src="@/assets/drivroute/icon_dinwei.png" mode="">
					<span>{{mapinfo.store_address}}</span>
				</div>
				<div class="driveinfo_map_photo">
					<img src="@/assets/drivroute/icon_model.png" mode="">
					<span>{{mapinfo.store_contact}}</span>
				</div>
			</div>
		</div>
		
		
		<!-- 个人信息组件 -->
		<div class="driveinfo_footer">
			<van-image class="center_avatar" round :src="serverUrl(userinfo?.avatar)" fit="cover"/>
			<span class="driveinfo_footer_name">{{userinfo.bio}}</span>
			<span class="driveinfo_footer_title">您的专属销售顾问{{userinfo.username}}随时为您服务</span>
		</div>
		
		
		
		<CarmodelInvite :invitelist="grouplist" :show="showCarModel" v-on:edit="handCarModel"></CarmodelInvite>
		
		<CarmodelInvite :invitelist="modellist" :show="showCarModelmodel" v-on:edit="handCarModelmodelad"></CarmodelInvite>
		
		<CarmodelInvite :invitelist="timelist" :show="showtime" v-on:edit="haneditdtime"></CarmodelInvite>
		
		<CarmodelInvite :invitelist="modellist" :show="showtypemodel" v-on:edit="handtypemodelad"></CarmodelInvite>
		<div class="user_footer"  style="height: 91px;" :style="{'top':(docmHeight - 91) + 'px'}">
			<div class="" @click="save">
				预览
			</div>
		</div>
	</div>
</template>

<script>
	import CarmodelInvite from '@/components/carmodel-invite.vue'
	import Map from '../../components/map.vue'
	export default {
		components: {
			CarmodelInvite,
			Map
		},
		data() {
			return {
				pageTitle:'创建邀请函',
				grouplist:[{name:'风范女神',id:2},{name:'精英男士',id:3},{name:'家庭有孩',id:4}],
				modellist:[],
				id:'',
				invitationinfo:{},
				name:'',
				activity:'',
				imgurl:'',
				lat:[],
				modeldis:'点击选择宣传图的车型',
				category:'点击选择用户分类',
				datatime:'',
				modeltype:'',
				modelid:'',
				group_name:'',
				content:'',
				showCarModel:false,
				showCarModelmodel:false,
				showtypemodel:false,
				showtime:false,
				timelist:[],
				title1:'',
				title2:'',
				title3:'',
				userinfo:{},
				firminfo:{},
				feedbackinfo:[],
				mapinfo:{},
				list1:'',
				list2:[],
				lat:[],
				group_id:'',
				scrollTop: 0,
				feedback_content_ids:[],
				islat:true,
				isdiv:false,
				array:[],
				list4:[],
				timeindex:'',
				modelindex:'',
			};
		},
		mounted() {
			this.id = this.$route.query.id
			this.getInvitationList()
			this.getCarModelList()
		},
		computed: {    
			docmHeight() {
				return this.$store.state.height;
			},
			list3(){
				let ss = ''
				this.list4.forEach((v,index) => {
					if(v){
						ss = ss + this.array[index]+v
					}else{
						ss = ss + this.array[index]+this.list2[index]
					}
					
				})
				ss = ss + this.array[this.array.length -1]
				ss = ss.replace(/1111111111/g, '<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
				return ss
			}
		},
		watch:{
			list4:{
				handler(newVal, oldVal) {
				},
      deep: true, // 加这个属性，深度监听
			},
		},
		methods: {
			// 创建
			async save(){
				if(this.modelid == '') return this.$toast.fail('车型配置为空')
				if(this.group_id == '') return this.$toast.fail('用户类型为空')
				this.list4.forEach((v,index)=> {
					if(v == ''){
						this.$toast.fail(this.list2[index].slice(1,this.list2.length-1) + '为空')
					}
				})
				const form = {}
				form.formwork_id = this.invitationinfo.id
				form.group_id = this.group_id
				form.content = this.list4.join(',')
				if(this.feedback_content_ids.length != 0){
					form.feedback_content_ids = this.feedback_content_ids.join(',')
				}
				// const {
				// 	data: res
				// } = await this.$http.post('/api/scsj/create_invitation_1_7',form)
				// const {
				// 	data: res2
				// } = await this.$http.post('/api/scsj/prediv_invitation_1_7_1',form)
					if(this.id != 7){
						this.$router.push({
						path: '/invitation/letter-info-preview?option='+JSON.stringify(form)
					})
					}else{
						this.$router.push({
							path: '/invitation/check-out-preview?option='+JSON.stringify(form)
						})
					}
			},
			// 切换图片
			async postimg(){
				const {
					data: res
				} = await this.$http.post('/api/scsj/get_scsj_group_image',{model_id:this.modelid,group_name:this.group_name})
				if (res.code == 1) {
					this.imgurl = res.data.res.image
				}
			},
			async getInvitationList() {
				const {
					data: res1
				} = await this.$http.post('/api/scsj/get_invitation_formwork_1_7_1',{id:this.id})
				if (res1.code == 1) {
					this.invitationinfo = res1.data.res
					this.imgurl = this.invitationinfo.scsj_invitation_formwork_content[0].images
					this.pageTitle = '创建' + this.invitationinfo.name
					if(this.id != 7){
						this.content = this.invitationinfo.scsj_invitation_formwork_content[1].content
						this.userinfo = this.invitationinfo.scsj_invitation_formwork_content[4].userinfo
						this.list2 = this.invitationinfo.scsj_invitation_formwork_content[1].variable.split(',')
						this.firminfo = this.invitationinfo.scsj_invitation_formwork_content[2]
						this.mapinfo = this.invitationinfo.scsj_invitation_formwork_content[3].position
						this.content.forEach((v,index) => {
							if(index == 0){
								this.list1 = v
							}else{
								this.list1 = this.list1 + '1111111111' + v
							}
						});
						let ss = ''
						this.list2.forEach((v2,index)=>{
							this.list4[index] = ''
							if(index== 0){
								this.array.push(this.list1.slice(0,this.list1.indexOf(v2)))
								ss = this.list1.slice(this.list1.indexOf(v2)+v2.length)
							}else if(index == this.list2.length -1 ){
								this.array.push(ss.slice(0,ss.indexOf(v2)))
								ss = ss.slice(ss.indexOf(v2)+v2.length)
								this.array.push(ss)
							}else{
								this.array.push(ss.slice(0,ss.indexOf(v2)))
								ss = ss.slice(ss.indexOf(v2)+v2.length)
							}
						})
						if(this.mapinfo?.store_lng_lat != null){
							const indexs = this.mapinfo.store_lng_lat.indexOf(',')
							this.lat[0] = parseFloat(this.mapinfo.store_lng_lat.slice(0,indexs)) 
							this.lat[1] = parseFloat(this.mapinfo.store_lng_lat.slice(indexs+1)) 
						}else{
							this.islat = false
						}
						
						
						
					}else{
						this.content = this.invitationinfo.scsj_invitation_formwork_content[1].content
						this.list2 = this.invitationinfo.scsj_invitation_formwork_content[1].variable.split(',')
						this.userinfo = this.invitationinfo.scsj_invitation_formwork_content[5].userinfo
						this.firminfo = this.invitationinfo.scsj_invitation_formwork_content[3]
						this.mapinfo = this.invitationinfo.scsj_invitation_formwork_content[4].position
						this.content.forEach((v,index) => {
							if(index == 0){
								this.list1 = v
							}else{
								this.list1 = this.list1 + '1111111111' + v
							}
						});
						let ss = ''
						this.list2.forEach((v2,index)=>{
							this.list4[index] = ''
							if(index== 0){
								this.array.push(this.list1.slice(0,this.list1.indexOf(v2)))
								ss = this.list1.slice(this.list1.indexOf(v2)+v2.length)
							}else if(index == this.list2.length -1 ){
								this.array.push(ss.slice(0,ss.indexOf(v2)))
								ss = ss.slice(ss.indexOf(v2)+v2.length)
								this.array.push(ss)
							}else{
								this.array.push(ss.slice(0,ss.indexOf(v2)))
								ss = ss.slice(ss.indexOf(v2)+v2.length)
							}
						})
						if(this.mapinfo.store_lng_lat != null && this.mapinfo != null){
							const indexs = this.mapinfo.store_lng_lat.indexOf(',')
							this.lat[0] = parseFloat(this.mapinfo.store_lng_lat.slice(0,indexs)) 
							this.lat[1] = parseFloat(this.mapinfo.store_lng_lat.slice(indexs+1)) 
						}else{
							this.islat = false
						}

						this.feedbackinfo = this.invitationinfo.scsj_invitation_formwork_content[2].feedback
						
						this.feedback_content_ids = this.feedbackinfo.map(v => {
							return v.id
						})
					}
					
					// this.index1 = this.content[0].indexOf('{客户称呼}')
					// if(this.index1 != -1){
					// 	this.content[0] = this.content[0].slice(0,this.index1) + this.name + this.content[0].slice(this.index1+6,this.content[0].length+1)
					// }
					// this.index2 = this.content[1].indexOf('{日期}')
					// this.index3 = this.content[1].indexOf('{车型}')
					// this.index4 = this.content[1].indexOf('{活动名称}')
				}
				this.isdiv = true
				const {
					data: res
				} = await this.$http.post('/api/scsj/get_car_model_image_1_4')
				if (res.code == 1) {
					this.modellist = res.data.res
				}
				
			},
			// 车新
			handCarModelmodel(e){
				this.showCarModelmodel = true
				setTimeout(() => {
					this.showCarModelmodel = false
				}, 1000)
			},
			// 车新
			handCarModelmodelad(e){
				this.modeldis = e.name
				this.modelid = e.id
				if(this.group_name != ''){
					this.postimg()
				}
			},
			// 类别
			handCarModelShow(e) {
				this.showCarModel = true
				setTimeout(() => {
					this.showCarModel = false
				}, 1000)
			},
			// 类别
			handCarModel(e) {
				this.group_name = e.name
				this.group_id = e.id
				this.category = e.name
				if(this.modelid != ''){
					this.postimg()
				}
			},
			// 时间变量
			handtime(e){
				this.timeindex = e
				this.showtime = true
				setTimeout(() => {
					this.showtime = false
				}, 1000)
			},
			haneditdtime(e){
				this.$set(this.list4, this.timeindex, e.id);
			},
			// 车类别变量
			handmodeltypeShow(e){
				this.modelindex = e
				this.showtypemodel = true
				setTimeout(() => {
					this.showtypemodel = false
				}, 1000)
			},
			handtypemodelad(e){
				this.$set(this.list4, this.modelindex, e.name);
				// this.list4[this.modelindex] = e.name
			},
			
			// 获取时间
			getCarModelList(){
				var myDate = new Date();
				  var beforeseven = new Date();
				  var thirty = new Date();
				  var sixty = new Date();
				  var custom = new Date();
				  //x.getDate() 返回月份的某一天，当前myDate.getDate()是返回当月的当天～ 比如今天是2021年09月17号，返回的是17
				  //x.setDate() 设置月份的某一天，当前myDate.setDate(myDate.getDate())是设置为2021年09月17号
				  myDate.setDate(myDate.getDate());
				  beforeseven.setDate(beforeseven.getDate()-1 - 6);
				  thirty.setDate(thirty.getDate()-1 - 29);
				  for (var i = 0; i < 60; i++) {
					  sixty = new Date()
						console.log(custom.getDate())
					  sixty.setDate(sixty.getDate() + i)
						if(custom.getDate() == this.Twodigits(sixty.getDate())){
							this.timelist.push({id:sixty.getFullYear() +
				      '年' + this.Twodigits(sixty.getMonth() + 1) + '月' + this.Twodigits(sixty.getDate()) + '日',name:'今日',})
						}else{
							this.timelist.push({id:sixty.getFullYear() +
				      '年' + this.Twodigits(sixty.getMonth() + 1) + '月' + this.Twodigits(sixty.getDate()) + '日',name: this.Twodigits(sixty.getMonth() + 1) + '月' + this.Twodigits(sixty.getDate()) + '日',})
						}
					  
				   }
			},
			Twodigits(num) {
			  return num < 10 ? '0' + num : num;
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.invitation{
	padding: 40px;
	
}
.letter{
	padding-bottom: 230px;
}
.invitationinputs{
		padding: 30px;
		margin-top: 25px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #2D2D2D;
		height: 88px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #ECECEC;
		img{
			width: 32px;
			height: 32px;
		}
	}

.driveinfo_top{
	margin-top: 20px;
	padding: 30px 40px;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	font-weight: normal;
	display: flex;
	flex-direction: column;
	color: #FFFFFF;
	line-height: 48px;
	.driveinfo_line{
		margin-top: 42px;
		height: 1px;
		background-color: #919191;
	}
	.input_name{
		margin-top: 25px;
		height: 88px;
		background: #2D2D2D;
		border: none;
		padding: 0 30px;
		font-size: 32px;
		width: 100%;
		box-sizing: border-box;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #ECECEC;;
		line-height: 88px;
	}
}
.imgbj{
	width: 670px;
	height: 580px;
}

.feedback{
	padding: 40px;
	.feedback_title{
		font-size: 36px;
		font-family: HYQiHeiY3-65;
		padding: 20px;
		text-align: center;
		font-weight: normal;
		color: #FFFFFF;
	}
	.feedback_list{
		margin-top: 30px;
		.feedback_list_title{
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
		}
		.feedback_list_imgs{
			padding: 40px 0;
			display: flex;
			align-items: center;
			img{
				width: 48px;
				height: 48px;
			}
			span{
				font-size: 32px;
				font-family: HYQiHeiY3-55;
				font-weight: normal;
				color: #FFFFFF;
				margin-left: 24px;
			}
		}
	}
}

.driveinfo_footer{
		padding: 40px;
		display: flex;
		align-items: center;
		flex-direction:column;
		.center_avatar{
			width: 120px;
			height: 120px;
			border: 1px solid #FA0037;
			border-radius: 50%;
		}
		.driveinfo_footer_name{
			margin-top: 32px;
			font-size: 40px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 40px;
		}
		.driveinfo_footer_title{
			margin-top: 32px;
			font-size: 32px;
			color: #BFBFBF;
			line-height: 32px;
		}
	}


.driveinfo_shop{
		border: 1px solid #979797;
		padding: 28px;
		margin: 60px 32px 0;
		display: flex;
		align-items: center;
		img{
			height: 144px;
			width: 144px;
		}
		span{
			margin-left: 40px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
		}
	}

.user_footer{
	z-index: 2;
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000000;
	text-align: center;
	div{
		width: 670px;
		height: 92px;
		line-height: 92px;
		background: #FA0037;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}

.driveinfo_map{
		padding: 40px 32px;
		display: flex;
		.driveinfo_map_left{
			height: 242px;
			width: 242px;
			background-color: #fff;
		}
		.driveinfo_map_rigth{
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.driveinfo_map_title{
				width: 350px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ACACAC;
				line-height: 40px;
			}
			.driveinfo_map_photo{
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 40px;
				}
			}
			.driveinfo_map_add{
				margin-top: 30px;
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					width: 350px;
					overflow:hidden;
					text-overflow:ellipsis;
					display: -webkit-box; 
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2; 
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 34px;
				}
			}
		}
}
</style>
